import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Form,
  Input,
  Divider,
  Select,
  Button,
  Checkbox,
  InputNumber,
  message,
  TimePicker,
  Modal,
  Table,
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import moment from "moment";
import axiosWithAuth from "../../../utils/axiosWithAuth";
import { TABLE_DATE_FORMAT } from "../../../common/constants";

//** Import Components */
import { useDashboardFormData } from "../../../hooks/useDashboardFormData";
import TextArea from "antd/lib/input/TextArea";
import LoadingForm from "../../../common/LoadingForm";
import ContentPageTitle from "../../../common/ContentPageTitle";
import { getRestaurant } from "../../../features/reducers/restaurants";
import UploadMedia from "../../../common/FormInputs/UploadMedia";

export default function OrderInfoUser(props) {
  const { order_id } = props;

  const [orderDetails, setOrderDetails] = useState();

  const dateFormat = TABLE_DATE_FORMAT;

  const columns = [
    {
      title: "Dish Name",
      key: "dish_name",
      render: (text) => <>{text.dish_name}</>,
    },
    {
      title: "Quantity",
      key: "quantity",
      render: (text) => <>{text.dish_quantity} </>,
    },
    {
      title: "Total",
      key: "total",
      render: (text) => <>$ {text.dish_price * text.dish_quantity} </>,
    },
  ];

  useEffect(() => {
    getOrderDetails();
  }, [order_id]);

  const getOrderDetails = async () => {
    try {
      const response = await axiosWithAuth().get(
        `/api/restaurant/order/getOrdersDetails/${order_id}`
      );

      console.log("RESPONSE", response.data.data.order);

      setOrderDetails(response.data.data.order);
    } catch (error) {
      console.error("Error getting orders:", error);
    }
  };

  return (
    <>
      {orderDetails && (
        <>
          <p>
            Order #{order_id} was placed on{" "}
            {moment(new Date(orderDetails.date_order)).format(dateFormat)} and
            is currently {orderDetails.order_status}
          </p>

          <Table columns={columns} dataSource={orderDetails.detail} />

          <p>
            <b>Subtotal: </b> {orderDetails.total_price_order / 1.1}
          </p>
          <p>
            <b>Total: </b> {orderDetails.total_price_order}
          </p>
        </>
      )}
    </>
  );
}
