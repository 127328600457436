import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  DatePicker,
  Divider,
  Input,
  InputNumber,
  Tooltip,
  Checkbox,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

//** Import Components */
import { useDashboardFormData } from "../../../../hooks/useDashboardFormData";
import SelectDropdown from "../../../../common/FormInputs/SelectDropdown";
import ContentFormSubtitle from "../../../../common/ContentFormSubtitle";
import { getAd } from "../../../../features/reducers/ads";
import { useParams } from "react-router";
import UploadMedia from "../../../../common/FormInputs/UploadMedia";
import LoadingForm from "../../../../common/LoadingForm";
import ListBackBtn from "../../../BusinessDashboard/components/ListBackBtn";
import { DATE_FORMAT } from "../../../../common/constants";
import axiosWithAuth from "../../../../utils/axiosWithAuth";

export default function AdSettings() {
  const [form] = Form.useForm();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [ad, setAd] = useState();
  const [minKillDate, setMinKillDate] = useState(moment().add(1, "day"));
  const [maxKillDate, setMaxKillDate] = useState(moment().add(1, "month"));
  const [isRecurrent, setIsRecurrent] = useState(false);

  useEffect(() => {
    getAdData();
  }, [id]);

  const getAdData = async () => {
    const response = await axiosWithAuth().get(
      `${process.env.REACT_APP_BE_API_URL}/api/advertisement/owner/${id}`
    );
    setAd(response.data);
  };

  useEffect(() => {
    dispatch(getAdData);
  }, [dispatch, id]);

  const [
    fields,
    setFieldData,
    isSubmitting,
    submitForm,
    onFinishFailed,
    loadingForm,
  ] = useDashboardFormData(ad);

  useEffect(() => {
    if (ad && ad.id !== undefined) {
      setFieldData();
    }
  }, [ad]);

  // Actualiza el rango de Kill Date cuando cambian los meses
  const handleMonthChange = (value) => {
    const minDate = moment().add(1, "day");
    const maxDate = moment().add(value, "months");
    setMinKillDate(minDate);
    setMaxKillDate(maxDate);

    // Resetear el Kill Date si queda fuera del nuevo rango
    const currentKillDate = form.getFieldValue("meta_ad_kill_date");
    if (
      currentKillDate &&
      (currentKillDate.isBefore(minDate) || currentKillDate.isAfter(maxDate))
    ) {
      form.setFieldsValue({ meta_ad_kill_date: null });
    }
  };

  return (
    <div>
      <ListBackBtn
        backURL="/account/advertisements"
        btnTxt="Back To Advertisements List"
      />

      {loadingForm ? (
        <LoadingForm />
      ) : (
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          initialValues={{
            ...fields,
            meta_ad_month_quantity: fields.meta_ad_month_quantity ?? 1,
          }}
          onFinishFailed={onFinishFailed}
          onFinish={async (values) => {
            values.meta_type = "pay";
            submitForm(
              values,
              `/api/advertisement/update/${ad.id}`,
              getAd(ad.id)
            );
          }}
          disabled={isSubmitting}
        >
          <ContentFormSubtitle title="Main Description" />

          <Form.Item
            label="Advertisement Name"
            name="name"
            rules={[{ required: true, message: "Please input your ad name!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="meta_ad_image"
            rules={[{ required: true, message: "Please input an image!" }]}
          >
            <UploadMedia
              setMedia={(file) => form.setFieldsValue({ meta_ad_image: file })}
              maxCount={1}
              label="Advertisement Image"
              aspectRatio={1.91}
              fieldName="meta_ad_image"
              form={form}
            />
          </Form.Item>

          <Form.Item label="URL" name="meta_ad_url">
            <Input placeholder="URL" />
          </Form.Item>

          <ContentFormSubtitle title="Date Settings" />

          <div className="form-row">
            <Form.Item
              label="Start Date"
              name="meta_ad_startdate"
              rules={[
                { required: true, message: "Please input your Start Date!" },
              ]}
            >
              <DatePicker format={DATE_FORMAT} />
            </Form.Item>

            <Form.Item
              label="Months"
              name="meta_ad_month_quantity"
              rules={[
                {
                  required: true,
                  message: "Please input an amount of months!",
                },
              ]}
            >
              <InputNumber
                addonAfter="month(s)"
                defaultValue={1}
                min={1}
                onChange={handleMonthChange}
              />
            </Form.Item>
          </div>

          <Form.Item name="meta_ad_recurrent" valuePropName="checked">
            <Tooltip title="If selected, the ad will remain active for the selected months and won't have a kill date.">
              <Checkbox
                onChange={(e) => {
                  setIsRecurrent(e.target.checked);
                  if (e.target.checked) {
                    form.setFieldsValue({ kill_date: null });
                  }
                }}
              >
                Recurrent
              </Checkbox>
            </Tooltip>
          </Form.Item>

          {/* Kill Date Field */}
          <Form.Item
            label="Kill Date"
            name="meta_ad_kill_date"
            rules={[
              {
                required: !isRecurrent,
                message: "Please select a Kill Date!",
              },
            ]}
          >
            <DatePicker
              format={DATE_FORMAT}
              disabled={isRecurrent}
              disabledDate={(current) =>
                current &&
                (current.isBefore(minKillDate) || current.isAfter(maxKillDate))
              }
            />
          </Form.Item>

          <Divider />

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isSubmitting}>
              {isSubmitting ? "Saving" : "Save Settings"}
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
}
