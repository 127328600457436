//** Import Modules */
import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Modal,
  Input,
  InputNumber,
  DatePicker,
  Checkbox,
} from "antd";
import moment from "moment";
import axiosWithAuth from "../../../utils/axiosWithAuth";
import { useSelector } from "react-redux";
import ContentFormSubtitle from "../../../common/ContentFormSubtitle";

export default function EventTicketForm(props) {
  const { event, isModalVisible, handleCancel, editedData, getTicketData } =
    props;

  const [form] = Form.useForm();

  const [isFreeTicket, setIsFreeTicket] = useState(false);

  const business = useSelector((state) => state.business.business);

  const handleCancelModal = () => {
    handleCancel();
  };

  useEffect(() => {
    if (editedData) {
      form.setFieldsValue({
        name: editedData.name,
        capacity: Number(editedData.capacity),
        description: editedData.description,
        start_date: moment(editedData.start_date),
        end_date: moment(editedData.end_date),
        price: editedData.price,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editedData]);

  // Can not select days before today and today
  // function disabledDate(current) {
  //   return current && current < moment(new Date());
  // }

  // Create the ticket
  const createNewTicket = async (values) => {
    values.end_date = moment(values.end_date).format("YYYY-MM-DD");
    values.start_date = moment(values.start_date).format("YYYY-MM-DD");
    values.event_id = event.id;

    try {
      const response = await axiosWithAuth().post(
        `${process.env.REACT_APP_BE_API_URL}/api/event-ticket/create`,
        values
      );

      console.log(response);

      const modal = Modal.success({
        title: "Ticket Created",
        content: `Ticket was created successfully!`,
      });

      setTimeout(() => {
        modal.destroy();
      }, 5000);

      handleCancel();
      form.resetFields();
      getTicketData();
    } catch (err) {
      console.log(err.response);

      const modal = Modal.error({
        title: "Error Creating Ticket",
        content: err.response.data.error,
      });

      setTimeout(() => {
        modal.destroy();
      }, 5000);
    }
  };

  const onFreeEventChecked = (e) => {
    setIsFreeTicket(e.target.checked);

    if (e.target.checked) {
      form.setFieldsValue({
        price: 0,
        isEventFree: true,
      });
    } else {
      form.setFieldsValue({
        price: 1,
        isEventFree: false,
      });
    }
  };

  const updateTicket = async (values) => {
    values.id = editedData.id;
    values.end_date = moment(values.end_date).format("YYYY-MM-DD");
    values.start_date = moment(values.start_date).format("YYYY-MM-DD");
    values.event_id = event.id;

    try {
      const response = await axiosWithAuth().put(
        `${process.env.REACT_APP_BE_API_URL}/api/event-ticket/update`,
        values
      );

      console.log(response);

      const modal = Modal.success({
        title: "Ticket Updated",
        content: `Ticket was updated successfully!`,
      });

      setTimeout(() => {
        modal.destroy();
      }, 5000);

      handleCancel();
      form.resetFields();
      getTicketData();
    } catch (err) {
      console.log(err.response);

      const modal = Modal.error({
        title: "Error Creating Ticket",
        content: err.message,
      });

      setTimeout(() => {
        modal.destroy();
      }, 5000);
    }
  };

  return (
    <Modal
      title="Event Ticket"
      open={isModalVisible}
      onCancel={handleCancelModal}
      footer={null}
    >
      <Form
        name="basic"
        form={form}
        autoComplete="off"
        layout="vertical"
        onFinish={(values) => {
          // Set price 0 if we have free selected, or just undefined
          if (values.price === undefined || values.price === "") {
            values.price = 0;
          }

          if (Object.keys(editedData).length) {
            updateTicket(values);
          } else {
            createNewTicket(values);
          }

          // dispatch(getEvent(event.id));
          getTicketData();
        }}
      >
        <Form.Item
          label="Ticket Type"
          name="name"
          tooltip="i.e. Free Ticket, Early Bird, Special Ticket"
          rules={[
            {
              required: true,
              message: "Please input your Ticket type!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        {business.connectAcc.payouts_enabled && (
          <>
            <Form.Item
              name="price"
              label="Price"
              rules={[
                {
                  required: isFreeTicket,
                  message: "Price is Required!",
                },
              ]}
            >
              <InputNumber
                min={1}
                style={{ width: "100%" }}
                disabled={isFreeTicket}
              />
            </Form.Item>

            <Form.Item name="isEventFree" label="Free Event ?">
              <Checkbox
                onChange={onFreeEventChecked}
                valuePropName="checked"
                checked={isFreeTicket}
              />
            </Form.Item>
          </>
        )}
        <Form.Item
          name="capacity"
          label="Capacity"
          tooltip="Limit of how many users can get this ticket"
          rules={[
            {
              required: true,
              type: "integer",
              message: "Please Select Valid Ticket Quantity!",
            },
          ]}
        >
          <InputNumber min={1} style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item label="Description" name="description">
          <Input.TextArea
            showCount
            maxLength={2000}
            autoSize={{ minRows: 5, maxRows: 8 }}
          />
        </Form.Item>
        <ContentFormSubtitle
          title="Promotion Period"
          subtitle="Specify the date range in which a user will be able to get this ticket"
        />
        <div className="form-row">
          <Form.Item
            label="Start Date"
            name="start_date"
            tooltip="The date in which a user can begin getting this ticket"
            rules={[
              {
                required: true,
                message: "Please input your Ticket Start date!",
              },
            ]}
          >
            <DatePicker
              disabledDate={(current) => {
                const today = moment().startOf("day");
                const eventDateMeta = event.meta_data.find(
                  (meta) => meta.meta_key === "event_date"
                );
                const eventDate = eventDateMeta
                  ? moment(
                      eventDateMeta.meta_value,
                      "YYYY-MM-DD HH:mm:ss"
                    ).endOf("day")
                  : null;

                return (
                  current &&
                  (current < today || (eventDate && current > eventDate))
                );
              }}
              format="MM/DD/YYYY"
            />
          </Form.Item>

          <Form.Item
            label="End Date"
            name="end_date"
            tooltip="The last date in which a user can get this ticket"
            rules={[
              {
                required: true,
                message: "Please input your Ticket End date!",
              },
            ]}
          >
            <DatePicker
              disabledDate={(current) => {
                const today = moment().startOf("day");
                const eventDateMeta = event.meta_data.find(
                  (meta) => meta.meta_key === "event_date"
                );
                const eventDate = eventDateMeta
                  ? moment(
                      eventDateMeta.meta_value,
                      "YYYY-MM-DD HH:mm:ss"
                    ).endOf("day")
                  : null;

                return (
                  current &&
                  (current < today || (eventDate && current > eventDate))
                );
              }}
              format="MM/DD/YYYY"
            />
          </Form.Item>
        </div>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
