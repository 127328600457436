import React from "react";
import OrderInfo from "./OrderInfo";
import ListBackBtn from "../../components/ListBackBtn";
import { useParams } from "react-router";

export default function OrderSettings(props) {
  const { ordersList } = props;
  const { id } = useParams();

  return (
    <>
      <ListBackBtn backURL="/business/orders" btnTxt="Back To Orders List" />
      <OrderInfo order_id={id} />
    </>
  );
}
