import React, { useEffect, useState } from "react";
import ContentPageTitle from "../../../../common/ContentPageTitle";
// import HelpButton from "../../../../common/HelpButton";
import { useManageAddons } from "../../../../hooks/useManageAddons";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, Link } from "react-router-dom";
import { getServicesList } from "../../../../features/reducers/services";
import axiosWithAuth from "../../../../utils/axiosWithAuth";
import ProductsAddonsSettings from "./ProductsAddonsSettings";
import { Alert } from "antd";

export default function ManageAddons(props) {
  const { business } = props;

  // Initiate action to get user's business list
  const user = useSelector((state) => state.user.user);

  const dispatch = useDispatch();
  // const servicesList = useSelector((state) => {
  //   return state.services.servicesList;
  // });

  const [addonsList, setAddonsList] = useState([]);

  useEffect(() => {
    getAddonsList();
  }, [business.id, dispatch]);

  const getAddonsList = async () => {
    try {
      if (business.id) {
        const APIURL = `/api/restaurant/extra/allExtrasFromRestaurant/${business.id}`;
        const res = await axiosWithAuth().get(APIURL);
        setAddonsList(res.data.data);
        console.log("res", res);
      }
    } catch (error) {
      console.log("Error getting addons: ", error);
    }
  };

  // Custom hook to manage the creation/deletion of business listing
  const hookData = {
    creatorID: business.id,
    dispatchAction: getAddonsList,
    deleteItemAPI: "/api/restaurant/extra",
    user: user,
    itemType: "extra",
    type: "service",
    modalData: {
      title: "Create New Addon",
      fieldLabel: "Addon Name",
    },
  };

  const [modalComponent, CreateItemButton, ListComponent] =
    useManageAddons(hookData);

  return (
    <div id="services-page">
      <ContentPageTitle title="Product Addons" icon="file-pen" />

      <div className="content-box">
        <Routes>
          <Route
            path="/"
            element={
              <>
                <ListComponent
                  emptyMessage="Seems like you don't have an service listing."
                  btnText="Create New Service"
                  listItems={addonsList}
                />

                <CreateItemButton btnText="Create New Addon" />
              </>
            }
            exact
          />

          <Route
            path="/:id"
            element={<ProductsAddonsSettings business={business} />}
            exact
          />
        </Routes>
      </div>

      {modalComponent}
    </div>
  );
}
