import React, { useEffect, useRef, useState } from "react";

import { Button, Form, Input } from "antd";
import { io } from "socket.io-client";
import axiosWithAuth from "../../../../utils/axiosWithAuth";
import ChatBoxMessage from "./ChatBoxMessage";
import {
  faPaperPlane,
  faCircleChevronLeft,
  faComments,
  faArrowDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Chats(props) {
  const { user, type, idBusiness } = props;

  const [messages, setMessages] = useState([]);
  const [chatList, setChatList] = useState([]);
  const [threadID, setThreadID] = useState(0);
  const [receiver, setReceiver] = useState();
  const [chatToken, setChatToken] = useState();
  const [userToken, setUserToken] = useState();
  const [isConnected, setIsConnected] = useState(false);
  const [isBusiness, setIsBusiness] = useState(false);

  const [form] = Form.useForm();
  const inputRef = useRef(null);
  const messagesEndRef = useRef(null);

  const messageForm = useRef(null);
  const socketRef = useRef(null);

  // Cargar lista de chats al montar el componente
  useEffect(() => {
    loadChatList();

    return () => {
      if (socketRef.current) {
        console.log("socket", socketRef.current);
        console.log("DESTROY");
        socketRef.current.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    loadChatList();
    getUnseenMessagesAmount();
    if (threadID !== 0) {
      updateSeenChats();
      getMessages().then(() => {
        // Scroll to the bottom after messages are loaded
        scrollToBottom();
      });
    }
  }, [threadID]);

  useEffect(() => {
    user.meta_data.forEach((element) => {
      if (element.meta_key === "login_session") {
        setUserToken(element.meta_value);
      }
    });
  }, [user]);

  // Efecto para conectar el chat cuando hay un threadID y chatToken
  useEffect(() => {
    if (threadID && chatToken) {
      connectToChat(chatToken);
    }
  }, [threadID, chatToken]);

  // Manejar cambios en la visibilidad del documento
  useEffect(() => {
    const handleVisibilityChange = () => {
      console.log("Visibility changed:", document.visibilityState);
      console.log("Socket:", socketRef.current);
      console.log("ChatToken:", chatToken);

      if (document.visibilityState === "hidden" && socketRef.current) {
        socketRef.current.disconnect();
        setIsConnected(false);
        console.log("Socket disconnected due to tab switch");
      } else if (document.visibilityState === "visible" && chatToken) {
        connectToChat(chatToken);
        console.log("Socket reconnected due to tab switch");
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [chatToken]);

  const getChatToken = async (ThreadID) => {
    try {
      const response = await axiosWithAuth().get(
        `/api/messages/keyThreads/${ThreadID}`
      );
      setChatToken(response.data.key);
    } catch (error) {
      console.error("Error getting chat token:", error);
    }
  };

  const connectToChat = async (token) => {
    console.log("Connecting to chat...");

    if (socketRef.current) {
      socketRef.current.disconnect();
    }

    try {
      let socketio = io(process.env.REACT_APP_BE_API_URL, {
        query: {
          token: token,
        },
      });

      socketio.on("connect", () => {
        console.log("Connected to chat server");
        socketRef.current = socketio;
        setIsConnected(true);
      });

      socketio.on("message", (data) => {
        console.log("Message received", data);
        if (data.chat_id === threadID) {
          setMessages((prevMessages) => [...prevMessages, data]);
          scrollToBottom(); // Opcional: desplazarse hacia abajo al recibir un nuevo mensaje
        }
        loadChatList();
        if (data.sender_id !== user.id) {
          console.log("mensaje recibido");
          updateSeenChats();
        }
      });

      socketio.on("disconnect", () => {
        console.log("Disconnected from chat server");
        setIsConnected(false);
      });
    } catch (error) {
      console.error("Error connecting to chat:", error);
      setIsConnected(false);
    }
  };

  const loadChatList = async () => {
    try {
      const data = {
        type: type,
        idBusiness: idBusiness,
      };
      const response = await axiosWithAuth().post(
        `/api/messages/listThreads`,
        data
      );
      setChatList(response.data);
    } catch (error) {
      console.log("Failed to load chat list: ", error);
    }
  };

  const changeChat = (participant_id, thread_id, is_business) => {
    setThreadID(thread_id);
    setReceiver(participant_id);
    setIsBusiness(is_business);
    getChatToken(thread_id);
  };

  const getMessages = async () => {
    try {
      const data = {
        idParticipant: receiver,
        isBusiness: isBusiness === 1 ? true : false,
      };
      const response = await axiosWithAuth().post(
        `/api/messages/message/${threadID}`,
        data
      );
      setMessages(response.data);
    } catch (error) {
      console.error("Error getting chat token:", error);
    }
  };

  const onFinish = async (values) => {
    if (isConnected && socketRef.current) {
      const data = {
        chat_id: threadID,
        sender_id: userToken,
        message: values.message,
        sender_type: isBusiness === 1 ? "user" : "business",
        receive_id: receiver,
      };

      socketRef.current.emit("message", data);
      form.resetFields();
    } else {
      console.error("Socket is not connected");
    }
  };

  const updateSeenChats = async () => {
    try {
      const data = {
        idThread: threadID,
        isBusiness: isBusiness === 1 ? false : true,
        idRegister: isBusiness === 1 ? user.id : idBusiness,
      };
      const response = await axiosWithAuth().post(
        `/api/messages/unseen/update`,
        data
      );
      console.log("response", response);
    } catch (error) {
      console.error("Error creating chat:", error);
    }
  };

  const getUnseenMessagesAmount = async () => {
    try {
      const data = {
        type: isBusiness === 1 ? "user" : "business",
      };
      const response = await axiosWithAuth().post(
        `/api/messages/getunseen/${user.id}`,
        data
      );
      console.log("getUnseenMessagesAmount response", response);
    } catch (error) {
      console.error("Error creating chat:", error);
    }
  };

  // Función para desplazar el chat al final
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="chats-container">
      {(threadID === null || window.innerWidth > 600) && (
        <div className="chats-list">
          {chatList.length > 0 &&
            chatList.map((element) => {
              console.log("ELEMENT", element);
              return (
                <div
                  className={`chat-element ${
                    element.thread_id === threadID ? "selected" : ""
                  }`}
                  key={element.thread_id}
                  onClick={() =>
                    changeChat(
                      element.participant_id,
                      element.thread_id,
                      element.is_business
                    )
                  }
                >
                  {element.participant}
                  {element.me_unseen_messages > 0 && (
                    <div className="unseen">{element.me_unseen_messages}</div>
                  )}
                </div>
              );
            })}
        </div>
      )}

      {(threadID || window.innerWidth >= 600) && isConnected ? (
        <div className="new-chatbox">
          <div className="chatbox-header">
            <div className="recipient-name">
              <div className="back">
                <FontAwesomeIcon
                  icon={faCircleChevronLeft}
                  onClick={() => setThreadID(null)}
                />
              </div>
              {isConnected ? <p>Connected</p> : <p>Connecting to chat...</p>}
            </div>
          </div>

          <div className="chatbox-body">
            <div className="messages-box">
              {messages.length > 0
                ? messages.map((element, index) => {
                    return (
                      <ChatBoxMessage
                        key={index + "_" + userToken + "_" + element.sender_id}
                        message={element.message}
                        user={user}
                        sender_id={element.sender_id}
                        date_sent={element.date_sent}
                      />
                    );
                  })
                : "No Messages Found"}

              <div ref={messagesEndRef} />
            </div>
            <Form
              onFinish={onFinish}
              form={form}
              autoComplete="off"
              ref={messageForm}
            >
              <Form.Item
                name="message"
                rules={[{ required: true, message: "Please input a message!" }]}
              >
                <Input ref={inputRef} placeholder="Type your message..." />
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button htmlType="submit">
                  <FontAwesomeIcon icon={faPaperPlane} />
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      ) : (
        <div className="empty-chat">
          <div>
            <FontAwesomeIcon
              icon={faComments}
              size="3x"
              style={{ marginBottom: "10px" }}
            />
            <p>No chat selected</p>
            <p>Please select a chat to start messaging</p>
          </div>
        </div>
      )}
    </div>
  );
}
