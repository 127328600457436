//** Import Modules */
import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  DatePicker,
  Divider,
  Input,
  InputNumber,
  Select,
  Tooltip,
  Checkbox,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

//** Import Components */
import { useDashboardFormData } from "../../../../hooks/useDashboardFormData";
import SelectDropdown from "../../../../common/FormInputs/SelectDropdown";
import ContentFormSubtitle from "../../../../common/ContentFormSubtitle";
import { getAd } from "../../../../features/reducers/ads";
import { useParams } from "react-router";
import UploadMedia from "../../../../common/FormInputs/UploadMedia";
import LoadingForm from "../../../../common/LoadingForm";
import ListBackBtn from "../../components/ListBackBtn";
import { DATE_FORMAT, FE_URL } from "../../../../common/constants";
import axiosWithAuth from "../../../../utils/axiosWithAuth";

export default function AdSettings(props) {
  // Used to build the form
  const [form] = Form.useForm();

  const { business } = props;

  //* Get the ad Data
  const { id } = useParams();

  // Initiate action to get event data
  const dispatch = useDispatch();
  // const ad = useSelector((state) => state.ads.ad);
  const [ad, setAd] = useState();
  const [events, setEvents] = useState([]);
  const [adType, setAdType] = useState("Business");
  const [minKillDate, setMinKillDate] = useState(moment().add(1, "day"));
  const [maxKillDate, setMaxKillDate] = useState(moment().add(1, "month"));
  const [isRecurrent, setIsRecurrent] = useState(false);

  useEffect(() => {
    getAdData();
  }, [id]);

  useEffect(() => {
    getEvents();
  }, [business]);

  const getAdData = async () => {
    try {
      const response = await axiosWithAuth().get(
        `${process.env.REACT_APP_BE_API_URL}/api/advertisement/owner/${id}`
      );

      setAd(response.data);

      if (Array.isArray(response.data.meta_data)) {
        const targetTypeMeta = response.data.meta_data.find(
          (item) => item?.meta_key === "target_type"
        );

        if (targetTypeMeta?.meta_value) {
          setAdType(targetTypeMeta.meta_value);
        }
      }
    } catch (error) {
      console.error("Error fetching ad data:", error);
    }
  };

  const getEvents = async () => {
    try {
      const response = await axiosWithAuth().get(
        `/api/event/business/${business.id}`
      );
      setEvents(response.data);
    } catch (error) {
      console.error("Error getting events data:", error);
    }
  };

  // Actualiza el rango de Kill Date cuando cambian los meses
  const handleMonthChange = (value) => {
    const minDate = moment().add(1, "day");
    const maxDate = moment().add(value, "months");
    setMinKillDate(minDate);
    setMaxKillDate(maxDate);

    // Resetear el Kill Date si queda fuera del nuevo rango
    const currentKillDate = form.getFieldValue("meta_ad_kill_date");
    if (
      currentKillDate &&
      (currentKillDate.isBefore(minDate) || currentKillDate.isAfter(maxDate))
    ) {
      form.setFieldsValue({ meta_ad_kill_date: null });
    }
  };

  useEffect(() => {
    dispatch(getAdData);
  }, [dispatch, id]);

  const [
    fields,
    setFieldData,
    isSubmitting,
    submitForm,
    onFinishFailed,
    loadingForm,
  ] = useDashboardFormData(ad);

  useEffect(() => {
    if (ad?.id) {
      form.resetFields();
      setFieldData();

      if (Array.isArray(ad.meta_data)) {
        const eventMeta = ad.meta_data.find(
          (meta) => meta.meta_key === "eventId"
        );

        if (eventMeta && eventMeta.meta_value) {
          const event_id = eventMeta.meta_value;

          const selectedEvent = events.find(
            (event) => event.id === Number(event_id)
          );

          form.setFieldsValue({
            meta_eventId: selectedEvent ? selectedEvent.id : undefined,
          });
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ad, events]);

  useEffect(() => {
    if (adType === "Event") {
      getEvents();
    }
  }, [adType]);

  const [adSize, setAdSize] = useState(1);

  const chooseAdSize = (value) => {
    if (value === "skyscraper") {
      setAdSize(0.2);
    } else if (value === "leaderboard") {
      setAdSize(8);
    } else {
      setAdSize(1);
    }
  };

  const handleAdTypeChange = (value) => {
    setAdType(value);
  };

  return (
    <div>
      <ListBackBtn
        backURL="/business/advertisements"
        btnTxt="Back To Advertisements List"
      />
      {loadingForm ? (
        <LoadingForm />
      ) : (
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          initialValues={{
            ...fields,
            meta_ad_month_quantity: fields.meta_ad_month_quantity ?? 1,
          }}
          onFinishFailed={onFinishFailed}
          onFinish={async (values) => {
            values.meta_type = "free";
            console.log("business", business);
            const meta_ad_url =
              adType === "Event"
                ? `${FE_URL}/events/${values.meta_eventId}`
                : business.meta_data.find(
                    (meta) => meta.meta_key === "business_handle"
                  )
                ? `${FE_URL}/business/${
                    business.meta_data.find(
                      (meta) => meta.meta_key === "business_handle"
                    ).meta_value
                  }`
                : null;
            values.meta_ad_url = meta_ad_url;

            submitForm(
              values,
              `/api/advertisement/update/${ad.id}`,
              getAd(ad.id)
            );
          }}
          disabled={isSubmitting}
        >
          <ContentFormSubtitle title="Main Description" />

          <Form.Item
            label="Advertisement Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please input your ad name!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="meta_ad_image"
            rules={[
              {
                required: true,
                message: "Please input an image!",
              },
            ]}
          >
            <UploadMedia
              setMedia={(file) =>
                form.setFieldsValue({
                  meta_ad_image: file,
                })
              }
              maxCount={1}
              label="Advertisement Image"
              aspectRatio={adSize}
              fieldName="meta_ad_image"
              form={form}
            />
          </Form.Item>

          <Form.Item
            label="Target Type"
            name="meta_target_type"
            rules={[
              {
                required: true,
                message: "Please select an Ad Type!",
              },
            ]}
          >
            <Select onChange={handleAdTypeChange}>
              <Select.Option value="Promote Business">
                Promote Business
              </Select.Option>
              <Select.Option value="Promote Event">
                Promote Event
              </Select.Option>
            </Select>
          </Form.Item>

          {adType === "Event" && (
            <Form.Item
              label="Event"
              name="meta_eventId"
              rules={[
                {
                  required: true,
                  message: "Please select an Event!",
                },
              ]}
            >
              <Select>
                {events.map((event) => (
                  <Select.Option key={event.id} value={event.id}>
                    {event.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}

          <ContentFormSubtitle title="Date Settings" />

          <div className="form-row">
            <Form.Item
              label="Start Date"
              name="meta_ad_startdate"
              rules={[
                {
                  required: true,
                  message: "Please input your Start Date!",
                },
              ]}
            >
              <DatePicker format={DATE_FORMAT} />
            </Form.Item>

            <Form.Item
              label="Months"
              name="meta_ad_month_quantity"
              rules={[
                {
                  required: true,
                  message: "Please input an amount of months!",
                },
              ]}
            >
              <InputNumber
                addonAfter="month(s)"
                defaultValue={1}
                min={1}
                onChange={handleMonthChange}
              />
            </Form.Item>
          </div>

          <Divider />

          <Form.Item name="meta_ad_recurrent" valuePropName="checked">
            <Tooltip title="If selected, the ad will remain active for the selected months and won't have a kill date.">
              <Checkbox
                onChange={(e) => {
                  setIsRecurrent(e.target.checked);
                  if (e.target.checked) {
                    form.setFieldsValue({ kill_date: null });
                  }
                }}
              >
                Recurrent
              </Checkbox>
            </Tooltip>
          </Form.Item>

          {/* Kill Date Field */}
          <Form.Item
            label="Kill Date"
            name="meta_ad_kill_date"
            rules={[
              {
                required: !isRecurrent,
                message: "Please select a Kill Date!",
              },
            ]}
          >
            <DatePicker
              format={DATE_FORMAT}
              disabled={isRecurrent}
              disabledDate={(current) =>
                current &&
                (current.isBefore(minKillDate) || current.isAfter(maxKillDate))
              }
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isSubmitting}>
              {isSubmitting ? "Saving" : "Save Settings"}
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
}
