import React, { useEffect, useState } from "react";
import ContentPageTitle from "../../../../common/ContentPageTitle";
import { useManagementOrders } from "../../../../hooks/useManagementOrders";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, Link } from "react-router-dom";
// import { getRestaurantsList } from "../../../../features/reducers/restaurants";
import OrderSettings from "./OrderSettings";
import axiosWithAuth from "../../../../utils/axiosWithAuth";

export default function ManageOrders(props) {
  const { business } = props;

  // Initiate action to get user's business list
  const user = useSelector((state) => state.user.user);

  //   const dispatch = useDispatch();
  // const restaurantsList = useSelector((state) => {
  //     console.log('state', state)
  //     return state.restaurants.restaurantsList;
  // });

  const [ordersList, setOrdersList] = useState([]);

  const getAllOrders = async () => {
    const APIURL = `/api/restaurant/order/allOrdersFromRestaurant/${business.id}`;

    axiosWithAuth()
      .get(APIURL)
      .then((res) => {
        const orderList = res.data.data.map((order) => {
          return {
            ...order,
            key: order.id,
          };
        });

        setOrdersList(orderList);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  useEffect(() => {
    getAllOrders();
  }, [business.id]);

  const [ListComponent] = useManagementOrders();

  return (
    <div id="restaurant-page">
      <ContentPageTitle title="Manage Orders" icon="file-pen" />

      <div className="content-box">
        <Routes>
          <Route
            path="/"
            element={
              <>
                <ListComponent
                  emptyMessage="Seems like you don't have an orders listing."
                  listItems={ordersList}
                />
              </>
            }
            exact
          />

          <Route
            path="/:id"
            element={<OrderSettings ordersList={ordersList} />}
            exact
          />
        </Routes>
      </div>
    </div>
  );
}
