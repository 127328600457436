//** Import Modules */
import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  Divider,
  DatePicker,
  TimePicker,
  Checkbox,
} from "antd";
import moment from "moment";

//** Import Components */
import { useDashboardFormData } from "../../../../hooks/useDashboardFormData";
import ContentPageTitle from "../../../../common/ContentPageTitle";
import SelectDropdown from "../../../../common/FormInputs/SelectDropdown";
import ContentFormSubtitle from "../../../../common/ContentFormSubtitle";
import UploadMedia from "../../../../common/FormInputs/UploadMedia";
import {
  dashboardGetCategories,
  dashboardGetCities,
} from "../../data/dashboardGetListData";
import LoadingForm from "../../../../common/LoadingForm";
import { DATE_FORMAT, TIME_FORMAT } from "../../../../common/constants";
import axiosWithAuth from "../../../../utils/axiosWithAuth";
import {
  validateMaxLength,
  validateEmail,
  validateNumbers,
  validateLength,
  validateName,
} from "../../../../utils/validations";

export default function EventInfo(props) {
  const { event, business } = props;
  console.log("event", event);

  // Used to build the form
  const [form] = Form.useForm();

  const [
    fields,
    setFieldData,
    isSubmitting,
    submitForm,
    onFinishFailed,
    loadingForm,
  ] = useDashboardFormData(event);
  console.log("fields", fields);

  useEffect(() => {
    if (event.id !== undefined) {
      setFieldData(["meta_event_promote_cities"]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event]);

  // Handle when an event is local or not
  const [isLocal, setIsLocal] = useState(false);

  const handleSelectEventMode = (value) => {
    if (value === "local") {
      setIsLocal(true);
    } else {
      setIsLocal(false);
    }
  };

  // Used to get dropdown menu data
  const [cities, setCities] = useState([]);

  useEffect(() => {
    dashboardGetCities(setCities);
  }, []);

  // used for promote all checkbox
  const [promoteToAll, setPromoteToAll] = useState(false);

  const onCheckboxChange = (e) => {
    setPromoteToAll(e.target.checked);
  };

  useEffect(() => {
    const checkboxVal = form.getFieldValue("meta_event_promoteAll");

    if (checkboxVal === 1 || checkboxVal === "1") {
      setPromoteToAll(true);
      form.setFieldsValue({
        meta_event_promoteAll: true,
      });
    } else if (checkboxVal === 0 || checkboxVal === "0") {
      setPromoteToAll(false);
      form.setFieldsValue({
        meta_event_promoteAll: false,
      });
    }
  }, [loadingForm, form]);

  // Used to get dropdown menu data
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    dashboardGetCategories(setCategories);
  }, []);

  // Used to handle sending notification to admin
  const sendAdminNotif = (eventData) => {
    const APIURL = "/api/event/notification/new";

    let fullEventData = {
      ...eventData,
      id: event.id,
    };

    const payload = {
      eventData: fullEventData,
      businessName: business.name,
    };

    axiosWithAuth()
      .post(APIURL, payload)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const disabledDate = (current) => {
    // Deshabilitar fechas anteriores a hoy
    return current && current < moment().startOf("day");
  };

  return (
    <div>
      <ContentPageTitle title="Event Information" icon="pen-to-square" />

      <Divider />

      {loadingForm ? (
        <LoadingForm />
      ) : (
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          initialValues={fields}
          defaultValue
          onFinishFailed={onFinishFailed}
          onFinish={async (values) => {
            try {
              console.log("values", values);

              // Validar que la fecha del evento no sea en el pasado
              if (moment(values.meta_event_date).isBefore(moment(), "day")) {
                throw new Error("The event date cannot be in the past.");
              }

              // Formatear las horas
              values.meta_event_endtime =
                values.meta_event_endtime.format("HH:mm");
              values.meta_event_starttime =
                values.meta_event_starttime.format("HH:mm");

              // Crear objetos moment para las horas
              const eventStart = moment(
                `${values.meta_event_date} ${values.meta_event_starttime}`,
                "YYYY-MM-DD HH:mm"
              );
              const eventEnd = moment(
                `${values.meta_event_date} ${values.meta_event_endtime}`,
                "YYYY-MM-DD HH:mm"
              );

              // Validar que la hora de inicio no sea en el pasado
              if (eventStart.isBefore(moment())) {
                throw new Error("The start time cannot be in the past.");
              }

              // Validar que la hora de inicio sea antes que la hora de fin
              if (eventStart.isSameOrAfter(eventEnd)) {
                throw new Error("The start time must be before the end time.");
              }

              // Unir el array de ciudades en un string si aplica
              if (!values.meta_event_promoteAll) {
                values.meta_event_promote_cities =
                  values.meta_event_promote_cities.join();
              }

              // Enviar notificación
              sendAdminNotif(values);

              // Enviar formulario
              await submitForm(values, `/api/event/update/${event.id}`);
            } catch (error) {
              alert(error.message);
            }
          }}
          disabled={isSubmitting}
        >
          <Form.Item
            name="meta_event_image"
            rules={[
              {
                required: true,
                message: "Please upload an image!",
              },
            ]}
          >
            <UploadMedia
              setMedia={(file) =>
                form.setFieldsValue({
                  meta_event_image: file,
                })
              }
              maxCount={1}
              label="Event Featured Image"
              form={form}
              fieldName="meta_event_image"
              description="1080px * 1080px Image - Max Size 2MB"
            />
          </Form.Item>

          <Form.Item
            label="Event Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please input your event name!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          {/* <Form.Item
            label='Capacity'
            name='meta_event_capacity'
            tooltip='What is the maximum capacity of the event venue? In the case of live events using Jitsi, capacity is limited to the number of participants allowed in your Zoom account.'
            rules={[
              {
                required: true,
                message: "Please input your event's capacity!",
              },
            ]}
          >
            <Input />
          </Form.Item> */}

          <Form.Item label="Event Description" name="meta_event_description">
            <Input.TextArea
              showCount
              maxLength={2000}
              autoSize={{ minRows: 5, maxRows: 8 }}
              className="formDescription"
            />
          </Form.Item>

          <Form.Item
            label="Short Description"
            name="meta_short_description"
            tooltip="You can place a short description. This is what will be shown on the event listing"
          >
            <Input showCount maxLength={120} className="formDescription" />
          </Form.Item>

          <Divider />

          <ContentFormSubtitle
            title="Organizer Information"
            subtitle="Please input the information of the person organizing this event"
          />

          <Form.Item
            label="Organizer's Name"
            name="meta_organizer_name"
            rules={[
              {
                required: true,
                message: "Please input organizer's name!",
              },
              { validator: validateName },
              { validator: (_, value) => validateMaxLength(_, value, 20) },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Organizer's Email"
            name="meta_organizer_email"
            rules={[
              {
                required: true,
                message: "Please input organizer's email!",
              },
              { validator: validateEmail },
              { validator: (_, value) => validateMaxLength(_, value, 40) },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Organizer's Phone"
            name="meta_organizer_phone"
            rules={[
              {
                required: true,
                message: "Please input organizer's phone!",
              },
              { validator: validateNumbers },
              { validator: (_, value) => validateLength(_, value, 10) },
            ]}
          >
            <Input />
          </Form.Item>

          <Divider />

          <ContentFormSubtitle title="Date Settings" />

          <div className="form-row">
            <Form.Item
              label="Event Date"
              name="meta_event_date"
              rules={[
                {
                  required: true,
                  message: "Please input your event date!",
                },
              ]}
            >
              <DatePicker format={DATE_FORMAT} disabledDate={disabledDate} />
            </Form.Item>

            <Form.Item
              label="Start Time"
              name="meta_event_starttime"
              rules={[
                {
                  required: true,
                  message: "Please input your start time!",
                },
              ]}
            >
              <TimePicker format={TIME_FORMAT} />
            </Form.Item>

            <Form.Item
              label="End Time"
              name="meta_event_endtime"
              rules={[
                {
                  required: true,
                  message: "Please input your end time!",
                },
              ]}
            >
              <TimePicker format={TIME_FORMAT} />
            </Form.Item>
          </div>

          <Divider />

          <ContentFormSubtitle title="Location Settings" />

          <Form.Item
            label="Event Type"
            name="meta_event_mode"
            // tooltip="If event is online, you will need to setup your Zoom Settings"
            rules={[
              {
                required: true,
                message: "Please choose a type!",
              },
            ]}
          >
            <SelectDropdown
              onSelect={(value) => handleSelectEventMode(value)}
              options={[
                { key: "local", value: "On-Location" },
                { key: "online", value: "Online" },
              ]}
            />
          </Form.Item>

          <Form.Item
            label="Venue Name"
            name="meta_event_venue"
            rules={
              isLocal
                ? [
                    {
                      required: true,
                      message: "Please input your venue name!",
                    },
                  ]
                : null
            }
            style={{
              display: !isLocal && "none",
            }}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Venue Address"
            name="meta_event_venue_address"
            rules={
              isLocal
                ? [
                    {
                      required: true,
                      message: "Please input your venue Address!",
                    },
                  ]
                : null
            }
            required={isLocal ? true : false}
            style={{
              display: !isLocal && "none",
            }}
          >
            <Input.TextArea
              showCount
              maxLength={2000}
              autoSize={{ minRows: 5, maxRows: 8 }}
              className="formDescription"
            />
          </Form.Item>

          <Divider />

          <ContentFormSubtitle
            title="Promotion Settings"
            subtitle="BIZZLL will use the information below in order to help promote the event across the platform"
          />

          <Form.Item
            label="Cities to promote"
            name="meta_event_promote_cities"
            tooltip="Choose which cities you wish to promote this event on"
          >
            <SelectDropdown
              mode="multiple"
              showSearch={true}
              disabled={promoteToAll}
              rules={
                promoteToAll
                  ? null
                  : [
                      {
                        required: true,
                        message: "Please choose Cities to promote !",
                      },
                    ]
              }
              options={
                cities && cities.length
                  ? cities.map((data) => {
                      return {
                        key: data.name,
                        value: data.name,
                      };
                    })
                  : []
              }
            />
          </Form.Item>

          <Form.Item name="meta_event_promoteAll" valuePropName="checked">
            <Checkbox onChange={onCheckboxChange}>
              Promote on all cities
            </Checkbox>
          </Form.Item>

          <Form.Item
            label="Category"
            name="meta_event_category"
            tooltip="Category that best fits the event"
            rules={[
              {
                required: true,
                message: "Please choose a category!",
              },
            ]}
          >
            <SelectDropdown
              showSearch={true}
              options={
                categories && categories.length
                  ? categories.map((data) => {
                      return {
                        key: data.name,
                        value: data.name,
                      };
                    })
                  : []
              }
            />
          </Form.Item>

          <Divider />

          <ContentFormSubtitle
            title="Social Media Campaign Settings"
            subtitle="BIZZLL will use the information below in order to help promote the event across social media"
          />

          <Form.Item
            label="Facebook Campaign"
            name="meta_facebook_campaign"
            rules={[
              {
                required: true,
                message: "Please add a Facebook campaign post!",
              },
            ]}
            tooltip="Facebook recommend posts should be somewhere between 40 and 50 characters. Max 2000 chars."
          >
            <Input.TextArea
              showCount
              maxLength={2000}
              autoSize={{ minRows: 5, maxRows: 8 }}
              className="formDescription"
              placeholder="This is an event campaign description sample. #GreatEvent #Miami"
            />
          </Form.Item>

          <Form.Item
            label="X Campaign"
            name="meta_twitter_campaign"
            tooltip="X recommends post should be between 71 and 100 characters. Max 280 characters."
            rules={[
              {
                required: true,
                message: "Please add a X campaign post!",
              },
            ]}
          >
            <Input.TextArea
              showCount
              maxLength={280}
              autoSize={{ minRows: 5, maxRows: 8 }}
              className="formDescription"
              placeholder="This is an event campaign description sample. via @BusinessX  #GreatEvent #Miami"
            />
          </Form.Item>

          <Form.Item
            label="Instagram Campaign"
            name="meta_instagram_campaign"
            tooltip="Captions can be up to 2000 characters long, and can contain up to 30 hashtags."
            rules={[
              {
                required: true,
                message: "Please add an Instagram campaign post!",
              },
            ]}
          >
            <Input.TextArea
              showCount
              maxLength={2000}
              autoSize={{ minRows: 5, maxRows: 8 }}
              className="formDescription"
              placeholder="This is an event campaign description sample. #GreatEvent #Miami"
            />
          </Form.Item>

          <Form.Item
            label="Linkedin Campaign"
            name="meta_linkedin_campaign"
            tooltip="Linkedin recommends post should be 140 characters. Max 1300 characters."
            rules={[
              {
                required: true,
                message: "Please add a Linkedin campaign post!",
              },
            ]}
          >
            <Input.TextArea
              showCount
              maxLength={1300}
              autoSize={{ minRows: 5, maxRows: 8 }}
              className="formDescription"
              placeholder="This is an event campaign description sample. #GreatEvent #Miami"
            />
          </Form.Item>

          <Divider />

          <h3>
            Please ensure that the info above is correct and accurate. BIZZLL
            will use is, AS IS, to promote the event across social media
            platforms.
          </h3>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isSubmitting}>
              {isSubmitting ? "Saving" : "Save Settings"}
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
}
