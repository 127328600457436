import React from "react";

export default function BusinessRegistrationClauseContent() {
  return (
    <>
      <h3>
        <strong>
          By registering your business on BIZZLL, you agree to the following
          terms and conditions:
        </strong>
      </h3>
      <p>
        Platform Commission Fee: A commission fee of 8% will be applied to all
        sales made through the BIZZLL platform.
      </p>
      <p>
        Merchant Service Fees: STRIPE will charge 2.9% + $0.30 per transaction
        for payment processing.
      </p>
      <p>
        Customer Usage Fee: Customers purchasing a product or service from a
        business registered on BIZZLL will be charged a 10% platform usage fee
        at checkout.
      </p>
      <p>Taxes: Any applicable taxes may apply based on local regulations.</p>
      <p>
        Fee Adjustments: BIZZLL reserves the right to modify fees and terms at
        any time, with notice provided to registered businesses.
      </p>
      <p>
        By completing your business registration, you acknowledge and agree to
        these terms. Failure to comply with BIZZLL’s policies may result in
        account suspension or termination.
      </p>

      <h3>
        <strong>Contact Us</strong>
      </h3>
      <p>
        BIZZLL, INC. welcomes your questions or comments regarding the Terms:
      </p>
      <p>BIZZLL, INC.</p>
      <p>
        Email Address:{" "}
        <a href="mailto:support@bizzll.com">support@bizzll.com</a>
      </p>
      <p>
        <em>
          <strong>Last updated: February 12, 2025</strong>
        </em>
      </p>
    </>
  );
}
