import React from "react";
// import PageTitle from "../../components/header/PageTitle";
import BusinessRegistrationClauseContent from "./BusinessRegistrationClauseContent";

export default function BusinessRegistrationClause() {
  return (
    <div>
      {/* <PageTitle pageTitle="Terms & Conditions" /> */}

      <div className="page-content">
        <div className="inner-container">
          <BusinessRegistrationClauseContent />
        </div>
      </div>
    </div>
  );
}
