// MessageItem.js
import React from "react";
import { Badge } from "antd";
import { DASHBOARD_URL } from "../../common/constants";

export default function MessageItem({
  type,
  name,
  unseenMessages,
  businessId,
}) {
  const manageBusiness = () => {
    localStorage.setItem("editBusiness", businessId);

    window.location.replace(`${DASHBOARD_URL}/business`);
  };

  console.log("unseenMessages", name, unseenMessages > 0);

  return (
    <>
      {type === "business" && unseenMessages > 0 ? (
        <div
          className="message-notification-user-bar business-notification"
          onClick={manageBusiness}
        >
          <span>
            <b>{name}:</b>{" "}
            <Badge count={unseenMessages} overflowCount={10} size="default" />
          </span>
        </div>
      ) : unseenMessages > 0 ? (
        <div className="message-notification-user-bar">
          <span>
            <b>{name}:</b>{" "}
            <Badge count={unseenMessages} overflowCount={10} size="default" />
          </span>
        </div>
      ) : null}
    </>
  );
}
