// import React, { useState } from "react";
// import { Upload, Button, Table, Select, message, Divider } from "antd";
// import { UploadOutlined } from "@ant-design/icons";
// import Papa from "papaparse";

// // Define the required fields for the import
// const requiredFields = [
//   { key: "dish_name", label: "Dish Name" },
//   { key: "dish_description", label: "Dish Description" },
//   //   { key: "dish_image", label: "Dish Image" },
//   { key: "dish_price", label: "Dish Price" },
//   //   { key: "dish_category_id", label: "Dish Category" },
// ];

// const ImportProducts = () => {
//   const [fileData, setFileData] = useState([]); // State to store the CSV file data
//   const [columns, setColumns] = useState([]); // State to store the detected columns
//   const [mapping, setMapping] = useState({}); // State to map CSV columns to system fields

//   // Function to handle the CSV file upload
//   const handleFileUpload = (file) => {
//     Papa.parse(file, {
//       complete: (result) => {
//         const data = result.data;
//         if (data.length > 0) {
//           setColumns(Object.keys(data[0])); // Extract column names from the CSV
//           setFileData(data); // Store the data in the state
//         } else {
//           message.error("The CSV file is empty or invalid.");
//         }
//       },
//       header: true,
//       skipEmptyLines: true,
//     });
//     return false;
//   };

//   // Function to update the column mapping
//   const handleMappingChange = (csvColumn, systemField) => {
//     setMapping((prev) => ({ ...prev, [csvColumn]: systemField }));
//   };

//   // Function to process and send the mapped data to the backend
//   const handleSubmit = () => {
//     if (Object.keys(mapping).length < requiredFields.length) {
//       return message.error("You must map all the required fields.");
//     }

//     // Transform the CSV data according to the user's defined mapping
//     const mappedData = fileData.map((row) => {
//       let formattedRow = {};
//       Object.keys(mapping).forEach((csvColumn) => {
//         formattedRow[mapping[csvColumn]] = row[csvColumn];
//       });
//       return formattedRow;
//     });

//     console.log("fileData", fileData);
//     console.log("mapping", mapping);

//     console.log("Sending to backend:", mappedData);
//     message.success("Data processed successfully.");
//   };

//   return (
//     <div className="content-box" style={{ padding: 20 }}>
//       {/* File upload component */}
//       <Upload
//         beforeUpload={handleFileUpload}
//         accept=".csv"
//         showUploadList={false}
//       >
//         <Button icon={<UploadOutlined />}>Upload CSV files</Button>
//       </Upload>

//       {columns.length > 0 && (
//         <>
//           {/* Preview table for the first few rows of the CSV file */}
//           <Table
//             dataSource={fileData.slice(0, 5)}
//             columns={columns.map((col) => ({ title: col, dataIndex: col }))}
//             pagination={false}
//             rowKey={(record, index) => index}
//             style={{ marginTop: 20 }}
//           />

//           <h3>Column Mapping</h3>
//           {/* Interface to map CSV columns to system fields */}
//           {columns.map((csvColumn) => (
//             <div key={csvColumn} style={{ marginBottom: 10 }}>
//               <strong>{csvColumn}:</strong>
//               <Select
//                 style={{ width: 200, marginLeft: 10 }}
//                 placeholder="Select field"
//                 onChange={(value) => handleMappingChange(csvColumn, value)}
//               >
//                 {requiredFields.map((field) => (
//                   <Select.Option key={field.key} value={field.key}>
//                     {field.label}
//                   </Select.Option>
//                 ))}
//               </Select>
//             </div>
//           ))}

//           {/* Button to submit the processed data to the backend */}
//           <Button
//             type="primary"
//             onClick={handleSubmit}
//             style={{ marginTop: 20 }}
//           >
//             Submit Data
//           </Button>
//         </>
//       )}
//     </div>
//   );
// };

// export default ImportProducts;

import React, { useState } from "react";
import { Upload, Button, Table, Select, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import Papa from "papaparse";
import axiosWithAuth from "../../../../utils/axiosWithAuth";

// Define the required fields for the import
const requiredFields = [
  { key: "dish_name", label: "Dish Name" },
  { key: "dish_description", label: "Dish Description" },
  { key: "dish_price", label: "Dish Price" },
  { key: "dish_category", label: "Dish Category" },
  { key: "dish_image", label: "Dish Image" },
];

const ImportProducts = (props) => {
  const [file, setFile] = useState(null); // Store uploaded file
  const [fileData, setFileData] = useState([]); // Store CSV parsed data
  const [columns, setColumns] = useState([]); // Store CSV detected columns
  const [mapping, setMapping] = useState({}); // Store mapping of CSV columns to system fields

  const { business } = props;

  // Function to handle CSV file upload
  const handleFileUpload = (file) => {
    setFile(file); // Store file for submission

    Papa.parse(file, {
      complete: (result) => {
        const data = result.data;
        if (data.length > 0) {
          setColumns(Object.keys(data[0])); // Extract column names
          setFileData(data); // Store parsed data
        } else {
          message.error("The CSV file is empty or invalid.");
        }
      },
      header: true,
      skipEmptyLines: true,
    });
    return false;
  };

  // Function to update column mapping
  const handleMappingChange = (csvColumn, systemField) => {
    setMapping((prev) => ({ ...prev, [csvColumn]: systemField }));
  };

  // Function to process and send the file and mapped data to the backend
  const handleSubmit = async () => {
    if (!file) {
      return message.error("Please upload a CSV file first.");
    }

    if (Object.keys(mapping).length < requiredFields.length) {
      return message.error("You must map all the required fields.");
    }

    // Transform CSV data based on the mapping
    // const mappedData = fileData.map((row) => {
    //   let formattedRow = {};
    //   Object.keys(mapping).forEach((csvColumn) => {
    //     formattedRow[mapping[csvColumn]] = row[csvColumn];
    //   });
    //   return formattedRow;
    // });

    // Prepare FormData for submission
    const formData = new FormData();
    formData.append("file", file); // Append file
    formData.append("mapping", JSON.stringify(mapping));

    console.log("formData", formData);

    try {
      const response = await axiosWithAuth().post(
        `/api/restaurant/dish/import/import-products/${business.id}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      if (response.ok) {
        message.success("Data processed successfully.");
      } else {
        message.error("Error processing the data.");
      }
    } catch (error) {
      console.error("Error:", error);
      message.error("An error occurred while sending the data.");
    }
  };

  return (
    <div className="content-box" style={{ padding: 20 }}>
      {/* File upload component */}
      <Upload
        beforeUpload={handleFileUpload}
        accept=".csv"
        showUploadList={false}
      >
        <Button icon={<UploadOutlined />}>Upload CSV files</Button>
      </Upload>

      {columns.length > 0 && (
        <>
          <Table
            dataSource={fileData.slice(0, 5)}
            columns={columns.map((col) => ({ title: col, dataIndex: col }))}
            pagination={false}
            rowKey={(record, index) => index}
            style={{ marginTop: 20 }}
          />

          <h3>Column Mapping</h3>
          {requiredFields.map((field) => (
            <div key={field.key} style={{ marginBottom: 10 }}>
              <strong>{field.label}:</strong>
              <Select
                style={{ width: 200, marginLeft: 10 }}
                placeholder="Select a CSV column"
                onChange={(value) => handleMappingChange(field.key, value)}
              >
                {columns.map((csvColumn) => (
                  <Select.Option key={csvColumn} value={csvColumn}>
                    {csvColumn}
                  </Select.Option>
                ))}
              </Select>
            </div>
          ))}

          {/* Submit Button */}
          <Button
            type="primary"
            onClick={handleSubmit}
            style={{ marginTop: 20 }}
          >
            Submit Data
          </Button>
        </>
      )}
    </div>
  );
};

export default ImportProducts;
